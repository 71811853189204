.prescription-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    text-align: right;
    color:#0056b3;
  }
  
  h2 {
    padding: 5px;
    color: #ffffff;
    width: 100%;
    background-color: #0056b3;
    border-radius: 7px;
  }
  section {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color:#0056b3;
    font-weight: bold;

  }
  
  input,
  select {
    width: 99%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #0056b3;
    border-radius: 5px;
  }
  
  textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #0056b3;
    border-radius: 5px;
  }

  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;

  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .actions button {
    margin-right: 10px;
  }
  
  @media (max-width: 768px) {
    .prescription-container {
      padding: 10px;
    }
  }
  